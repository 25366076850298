
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { IPartModelWithDepartmentName } from '@/core/interfaces/Models';
import { DonViService } from '@/core/services/DonVi.service';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { useRouterCustom } from '../../../../core/hooks/use-router-custom';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

import BoPhanService from '@/core/services/BoPhan.service';
import PartTable from '@/views/crafted/pages/part/part-table/PartTable.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
  name: 'danh-sach-bo-phan',

  components: { ButtonCustom, PartTable, PageRowLayout },

  setup() {
    const { push } = useRouterCustom();
    const { ButtonsType } = useButtonCustom();
    const store = useStore();
    const parts = ref<IPartModelWithDepartmentName[]>([]);

    onMounted(async () => {
      setCurrentPageTitle("Bộ phận");

      const {
        data: {
          data: { data },
        },
      } = await BoPhanService.list();
      const {
        data: {
          data: { ten_don_vi },
        },
      } = await DonViService.fetchCurrentDepartmentById(
        store.getters.currentUser.departmentId
      );
      parts.value = data.map((item) => ({
        ...item,
        departmentName: ten_don_vi,
      }));
    });

    return {
      parts,
      push,
      ButtonsType,
    };
  },
});
