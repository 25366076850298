
import { defineComponent, PropType } from "vue";

import { IPartModelWithDepartmentName } from "@/core/interfaces/Models";

export default defineComponent({
  name: "part-table",
  props: {
    items: {
      type: Array as PropType<Array<IPartModelWithDepartmentName>>,
    },
  },
  methods: {
    detailLink(id) {
      return `/quan-ly/bo-phan/chi-tiet/${id}`;
    },
    updateLink(id) {
      return `/quan-ly/bo-phan/cap-nhat/${id}`;
    },
  },
});
