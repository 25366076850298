import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    style: {"width":"100%"},
    "empty-text": "Chưa có bộ phần nào trong đơn vị này",
    size: "small",
    "cell-style": {padding: '0', height: '15px'},
    data: _ctx.items,
    border: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "ID",
        property: "id"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tên",
        property: "ten_bo_phan"
      }),
      _createVNode(_component_el_table_column, {
        label: "Mã",
        property: "ma_bo_phan"
      }),
      _createVNode(_component_el_table_column, {
        label: "Địa chỉ",
        property: "dia_chi"
      }),
      _createVNode(_component_el_table_column, {
        label: "Mô tả",
        property: "mo_ta"
      }),
      _createVNode(_component_el_table_column, {
        label: "Đơn vị",
        property: "departmentName"
      }),
      _createVNode(_component_el_table_column, null, {
        default: _withCtx((scope) => [
          _createVNode(_component_router_link, {
            class: "btn btn-info align-self-center me-2",
            to: _ctx.detailLink(scope.row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { style: {"font-size":"1.5em"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_InfoFilled)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"]),
          _createVNode(_component_router_link, {
            class: "btn btn-success align-self-center",
            to: _ctx.updateLink(scope.row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { style: {"font-size":"1.5em"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_Edit)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}